import React,{useEffect} from "react";
import "./magazine.css";

import mag1 from '../../assets/mag 1.jpg';
import mag2 from '../../assets/maag 2.jpg';
import mag3 from '../../assets/mag 3.jpg';
import mag4 from '../../assets/mag 4.jpg';
import mag5 from '../../assets/mag 5.jpg';
import mag6 from '../../assets/mag 6.jpg';
import mag7 from '../../assets/mag 7.jpg';
import mag8 from '../../assets/mag 8.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

const MagazineScroller = () => {

    useEffect(() => {
        AOS.init();
      }, []);

  // Example magazine data
  const magazines = [
    { id: 1, image: mag1, title: "Magazine 1" },
    { id: 2, image: mag2, title: "Magazine 2" },
    { id: 3, image: mag3, title: "Magazine 3" },
    { id: 4, image: mag4, title: "Magazine 4" },
    { id: 5, image: mag5, title: "Magazine 5" },
    { id: 6, image: mag6, title: "Magazine 6" },
    { id: 7, image: mag7, title: "Magazine 7" },
    { id: 8, image: mag8, title: "Magazine 8" },
   
    
  ];

  return (

    <div className="scroller-container" id="magazine">
        <div className="magazine-text-section">
        <h1 data-aos="zoom-out-left">Magazines</h1>
        <p data-aos="zoom-out-right">
            I am thrilled to introduce YOUTH, my upcoming magazine that aims to engage and inspire readers through a variety of topics. This project is a testament to my dedication to fostering creativity and dialogue among young minds. To support this initiative, I have established my own publication, ensuring that both the magazine and my books reach a wider audience. Join me in exploring diverse themes and ideas in the pages of YOUTH.
        </p>
        </div>     
      <div className="scroller" div data-aos="zoom-in-up">
        {[...magazines, ...magazines].map((mag, index) => (
          <div className="magazine" key={index}>
            <img src={mag.image} alt={mag.title} />
            
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default MagazineScroller;
