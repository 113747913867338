import React, { useState, useEffect } from 'react';
import './books.css';
import book1 from '../../assets/book1.png';
import book2 from '../../assets/book2.png';
import book3 from '../../assets/book3.png';
import book4 from '../../assets/book4.png';
import book5 from '../../assets/book5.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

function NovelsSection() {
    useEffect(() => {
        AOS.init();
      }, []);

  const [selectedBook, setSelectedBook] = useState(null);

  const books = [
    { src: book1, alt: 'Book 1', title: 'මරණය අබියස ', description: 'පොලිස් පරීක්ෂක කෙමිත මඩුගල්ල මහතා සහ දසුන්ගේ වීර වික්‍රම ඇරඹෙන පලමු කොටස මෙයයි.මවකගේ සෙනෙහස ගැන කියැවෙන පොතක්.' },
    { src: book2, alt: 'Book 2', title: 'කොල්ලකෑමක් පසුපස', description: 'පාසල් යාලුවන් අතර තිබුනු පාපැදි තරගය අතරමැද පාපැදියක් අතුරුදහන්.යාලුවෝ පස්දෙනා එකතුවෙලා හොයාගනීවිද' },
    { src: book3, alt: 'Book 3', title: 'සෝලෝ මන්දිරයේ අබිරහස ', description: 'දස්කම් මිතුරන් නමින් සංගමයක් පිහිටුවා ගනිමින් අබිරහසක් විසදන්නට යන ඔවුන් අතීත අබිරහසක් එක්ක ගනුදෙනු කරන්නේ ඇයි?ඔවුන්ට මොනවද මේ අවශ්‍ය?' },
    { src: book4, alt: 'Book 4', title: "කස්ටඩි", description: 'කටින් විතරක් ට්‍රිප් යන අය අතරේ ඇත්තටම ට්‍රිප් ගියානම්.. එහෙම ගිහිං ඉදපු එකම එක ගෑණු ළමයා අතුරුදහන් උනා නම්..ඒ යාලුවෝ අතරේ ඔවුන්ගෙන් කවුරු කවුරු ඒ කෙල්ලට ආදරේ කරලා ඇත්ද?පණට පණ වගේ යාලුවෝ ඇත්තටම ඉදීවිද ?මේ සියල්ල කියවන්න ..ඔයා කස්ටඩි කියවන්නම ඕනි ..' },
    { src: book5, alt: 'Book 5', title: 'සැඟවුණු ගුහාවේ අබිරහස', description: 'අටේ කල්ලියේ අභිරහස් එකතුවට තවත් එකක් එකතුවෙන්නයි යන්නේ..එංගලන්තය භූමිය කරගත් ඔවුන්ට තම මිතුරන් සමග එක්ව විවිධ කරදර බාධක වලට හසුවන අයට උදව් කරන්න් ගිහින් මේ මොනාද කරගත්තේ' },
  ];

  const openBookDetails = (book) => {
    setSelectedBook(book);
  };

  const closeBookDetails = () => {
    setSelectedBook(null);
  };

  return (
    <div className="novels-container" id='books'>
      <div className="novels-text-section">
        <h1 data-aos="zoom-out-left">Novels I've Written</h1>
        <p data-aos="zoom-out-right">
        My journey began with my first book, Sengawunu Guhawee Apirahasa, and I continue to explore new narratives with my latest work, Custody. Each story reflects my commitment to storytelling, and I am excited to share my literary journey with readers around the world
        </p>
      </div>
      <div className="books-gallery">
        {books.map((book, index) => (
          <img
            key={index}
            src={book.src}
            alt={book.alt}
            className={`book-cover ${book.className}`}
            onClick={() => openBookDetails(book)}
            div data-aos="fade-down"
          />
        ))}
      </div>
      {selectedBook && (
        <div className="book-popup">
            <div className="book-card">
            <button className="close-button" onClick={closeBookDetails}>×</button>
            <div className="book-content">
                <img src={selectedBook.src} alt={selectedBook.alt} className="popup-book-cover" />
                <div className="book-details">
                <h2>{selectedBook.title}</h2>
                <p>{selectedBook.description}</p>
                </div>
            </div>
            </div>
        </div>
        )}
    </div>
  );
}

export default NovelsSection;
