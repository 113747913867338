import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from "../../assets/logoo.png";


import "./nav.css"
import { MdHeight } from 'react-icons/md';

const Navbar = () => {

    //Settings mobile view
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)


    //color chnage in nav when scrolling
    const [color, setColor] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeNav)

    //Close mobile view
    const closeMenu = () => setClick(false)

    return (
        <div className={color ? 'header header-bg' : 'header'}>
            <nav className='navbar'>
                <div className='logo'>
                    <img src={logo} alt='logo' /> 

                </div>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <div className='nav-item'>
                        <a href='/' onClick={closeMenu}>Home</a>
                    </div>
                    <li className='nav-item'>
                        <a href='#about' onClick={closeMenu}>About</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#books' onClick={closeMenu}>Books</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#magazine' onClick={closeMenu}>Magazine</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#contact' onClick={closeMenu}>Contacts</a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar;