import React, { useEffect } from 'react'
import './auther.css';
import images from '../../assets/rashi.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AuthorProfile = () => {
    useEffect(() => {
        AOS.init();
      }, []);

    
  return (
    <div className="container" id='about' >
      <div className="text-section">
        <h1 data-aos="zoom-out-left">
          Writing with <span className="highlight" >ME</span>
        </h1>

        <p data-aos="zoom-out-right">
        I am Ahamed Raashid Lathfan, a writer . As a young author, I have written seven books, five of which have been published. My journey began with my first book, Sengawunu Guhawee Apirahasa, and my latest work, Custody, continues my passion for storywriting.<br></br>


        Currently, I am developing a magazine called YOUTH, which will feature a variety of topics to engage and inspire readers. To support this project, I established a publication to manage the printing of both the magazine and my books. Looking ahead, I am eager to continue expanding my work, producing more books to reach a wider audience.Above all, I am a person with a clear vision and purpose, committed to achieving my goals.</p>
      </div>
      <div className="image-section" data-aos="fade-down-left">
        <img src={images} alt="Author" className="author-image" />
      </div>
    </div>
  );
}

export default AuthorProfile;
