import book1Image from "../../assets/i1.png";
import "./hero.css"; 
import Navbar from "../nav/nav";
import imagefoot from "../../assets/Vector.svg";
import React, { useState, useEffect } from "react";

import book2Image from "../../assets/book2.png";
import book3Image from "../../assets/book3.png";
import book4Image from "../../assets/book4.png";

import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {

    useEffect(() => {
        AOS.init();
      }, []);

    const books = [
        {
          title: "New Release - කස්ටඩි",
          description:
            "කටින් විතරක් ට්‍රිප් යන අය අතරේ ඇත්තටම ට්‍රිප් ගියානම්.. එහෙම ගිහිං ඉදපු එකම එක ගෑණු ළමයා අතුරුදහන් උනා නම්.. පණට පණ වගේ යාලුවෝ ඇත්තටම ඉදීවිද ?                                             මේ සියල්ල කියවන්න ..ඔයා කස්ටඩි කියවන්නම ඕනි ..",
          buttonText: "More Info",
          image: book4Image,
        },
        {
          title: "කොල්ලකෑමක් පසුපස",
          description:
            "පාසල් යාලුවන් අතර තිබුනු පාපැදි තරගය අතරමැද පාපැදියක් අතුරුදහන්.යාලුවෝ පස්දෙනා එකතුවෙලා හොයාගනීවිද",
          buttonText: "More Info",
          image: book2Image,
        },
        {
          title: "සෝලෝ මන්දිරයේ අබිරහස ",
          description:
            "දස්කම් මිතුරන් නමින් සංගමයක් පිහිටුවා ගනිමින් අබිරහසක් විසදන්නට යන ඔවුන් අතීත අබිරහසක් එක්ක ගනුදෙනු කරන්නේ ඇයි?ඔවුන්ට මොනවද මේ අවශ්‍ය?",
          buttonText: "More Info",
          image: book3Image,
        },
      ];

      const handleScrollToBooks = () => {
        const bookSection = document.getElementById("books");
        if (bookSection) {
          bookSection.scrollIntoView({ behavior: "smooth" });
        }
      };
    
      const [currentBookIndex, setCurrentBookIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Trigger animation
      setAnimate(true);
      setTimeout(() => {
        // Change book and reset animation
        setCurrentBookIndex((prevIndex) => (prevIndex + 1) % books.length);
        setAnimate(false);
      }, 100); // Match animation duration
    }, 6500); // Change book every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const currentBook = books[currentBookIndex];

  return (
    <div className="hero-container">
      <Navbar />

      <header className="header-section">
        {/* Wrapper for animation */}
        <div className={`content-wrapper ${animate ? "slide-right" : ""} slide` } >
          <div className="header-content">
          <h2 data-aos="fade-down">{currentBook.title}</h2>
          <p data-aos="fade-left"> {currentBook.description}</p>
            <button className="buy-button" onClick={handleScrollToBooks}>{currentBook.buttonText}</button>
          </div>

          <div className="bookimg" div data-aos="zoom-in-left">
            <img src={currentBook.image} alt={currentBook.title} />
          </div>
        </div>

        
        <div className="wave-footer">
          <img src={imagefoot} alt="Wave Background" />
        </div>
      
      </header>

      {/* Footer Section */}
      
    </div>
  );
};

export default Hero;
