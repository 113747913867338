import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Hero from './componets/hero/hero';
import AuthorProfile from './componets/authur/authur';
import NovelsSection from './componets/books/books';
import Footer from './componets/contact/contact';
import MagazineShowcase from './componets/magazine/magazine';
import AOS from 'aos';
import 'aos/dist/aos.css';

import FullPageLoadingSpinner from './load/load';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
    
      useEffect(() => {
        
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);
    
      if (isLoading) {
        return < FullPageLoadingSpinner/>;
      }


  return (
    <div className="App">
      
      <Hero />
     <AuthorProfile />
     <NovelsSection />
     <MagazineShowcase />
     <Footer />
    </div>
  );
}

export default App;
