import React,{useEffect} from "react";
import './contact.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram,faDiscord, faTiktok } from '@fortawesome/free-brands-svg-icons';

import AOS from 'aos';
import 'aos/dist/aos.css';


const Footer = () => {
    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <footer className="footer" id="contact">
            
          <div className="footer-content">
            <div className="footer-section1">
              
              <p>I am Ahamed Raashid Lathfan, a passionate young author with seven books to my name, five of which have been published. My journey began with my first book, Sengawunu Guhawee Apirahasa, and I continue to explore new narratives with my latest work, Custody.</p>
            </div>
            <div className="footer-section2">
              <ul className="nav-footer">
                      <p>
                      <a href="https://www.facebook.com/RaashiPublication?mibextid=ZbWKwL"><FontAwesomeIcon icon={faFacebook} /> facebook</a>
                      </p>
                      <p>
                      <a href="https://www.tiktok.com/@raashipublication?_t=8qz3JLaI0yz&_r=1"><FontAwesomeIcon icon={faTiktok} /> tiktok</a>
                      </p>
                      <p>
                      <a href="https://www.instagram.com/raashi_publication_/profilecard/?igsh=MzhkazVzMTE5dHI2"><FontAwesomeIcon icon={faInstagram} /> instagram</a>
                      </p>
                </ul>
            </div>
            <div className="footer-section3">
              <h2>Contact Us</h2>
              
              <p>Email: raashipublication@gmail.com</p>
              <p>Whatsapp: +94 701800818</p>              
            </div>
            
          </div>

          <div className="footer-socials">
          <div className="social-icons">
            
            
            

          </div>
          </div>
          
          <div className="footer-bottom">
            <p>&copy; 2024 Raashi Publication. All rights reserved.</p>
            <p className="powered-by">
                Powered by <a href="https://www.ignixsolutions.com" target="_blank" rel="noopener noreferrer">Ignix Solutions</a>
            </p> 
            </div>
        </footer>
      );

};

export default Footer;